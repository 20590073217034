.page-not-found-container {
    height: 100vh;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.number-container{
    margin-bottom: 20px;
}
.number-error{
    font-size: 100px;
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif 
    
}
.text-container{
    margin-bottom: 40px;
}
.text-error{
    font-size: 50px;
    font-style: oblique;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif
}
.paragraph-container{
    margin-bottom: 20px;
}
.paragraph-error{
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace
}
.btn-container{
    margin-top: 40px;
}
.btn-redirect{
}

