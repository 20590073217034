.Input_Message__3RA1q{
    font-size: 10px !important
    
    }
 .e-mb-sm{
     background-color: #FF9700 !important;
     padding: 2px 10px !important;
     color: #fff !important;
     border-radius: 15px;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
        padding: 6px !important;
        border: none !important;
    }
    .input_border,.Input_Input__TA-uc {
        background-color: transparent;
        border:none !important;
        font-size: 16px !important;

    }
    .borderInput{
        border: 1px solid #dadada !important;
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 0% !important;
    }

    .btn_save{
        background-color: #FF9700 !important;
    }