/* Estilo para el contenedor del scroll */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Estilo para la barra del scroll */
  ::-webkit-scrollbar-thumb {
    background-color: #c7c5c4;
    border-radius: 150px;
  }
  
  /* Estilo para el extremo del scroll */
  ::-webkit-scrollbar-thumb:vertical {
    border-radius: 150px;
  }
  
  /* Estilo cuando se pasa el cursor por encima de la barra del scroll */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #c7c5c4;
  }
  
  /* Estilo para el track (fondo) del scroll */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Estilo para la barra horizontal del scroll */
::-webkit-scrollbar-thumb:horizontal {
  background-color: #c7c5c4;
  border-radius: 150px;
  /* Ajusta el ancho de la barra horizontal según tus necesidades */
  width: 10px;
}

/* Estilo cuando se pasa el cursor por encima de la barra horizontal del scroll */
::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #c7c5c4;
}

  