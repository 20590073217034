
.container{
    padding-top: 35px;
    padding-bottom: 35px;
    padding-right: 30px;
    padding-left: 30px;
    border: 1px solid rgb(150, 139, 139);
    min-height: 100%;
    max-height: 100%;
}
.container-form{
    padding-top: 35px;
    padding-bottom: 35px;
    padding-right: 30px;
    padding-left: 30px;
    border: 1px solid rgb(150, 139, 139);
}
.title-container{
    width: 100%;
    height: 50px;
    margin: 0px !important;
    text-align: center;
}
.title{
    width: 300px;
    font-weight: bold !important;
    font-size: 50px;
    /* text-decoration-line: underline; */
}

.firstparagraph-container{
    /* margin-top: -80px !important; */
    width: 100%;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 10px;
    padding: 10px;
}

.clauses-container-title{
    width: auto;
    /* margin-top: 30px !important; */
    display: flex;
    justify-content: start;
    height: 50px;
}
.order-clauses{
    font-weight: bold !important;
    margin-right: 5px !important;
}
.name-clauses{
    font-weight: bold !important;
    margin-left: 5px !important;
}
.clauses-container-text{
    width: 100%;
    /* margin-top: -100px !important; */
    border: 1px solid rgb(218, 218, 218);
    border-radius: 10px;
    padding: 10px;
}
.text-field{
    /* border: 1px solid rgb(219, 6, 6); */
}