.btn_add{
    color:#fff;
    background-color:#16556b;
    padding: 4px 15px;
    border: none;
    font-size: 16px;
    border-radius: 5px;
    
}

.borderInput{
    font-size: 10px;
}