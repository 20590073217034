Departments.tabs{
    background-color: #FF9700!important;
}
.tittle{
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #16556b !important;
}
.tittle-level2{
    font-size: 25px !important;
    font-weight: 700 !important;
    color: #16556b !important;
}
.link__css{
    text-decoration: none!important;
    /* font-style: italic; */
    
    /* font-size: 22px; */    
    /* font-family:  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}
.link__css-back{
    /* text-decoration: none; */
    font-weight: bold !important;
    color: #202020;
    /* color: #1c4e81!important; */
/* font-weight: bold; */
    /* font-size: 22px; */    
    /* font-family:  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}
.icon_back{
    margin-bottom: -4px;
}
